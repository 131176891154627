import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableRegion extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'desc',
        page: 1
    };
    formId = 'region-search-form';
    varPrefix = 'searchTableRegion';
    loadParamsRegExp = [
        /\/region\/(.*)/g
    ];

    apiUrl = '/api/regions';
    sortDef = [
        { key: 'name', name: 'Name der Kommune' },
        { key: 'rgs', name: 'Regionalschlüssel'},
        { key: 'updated', name: 'Änderungsdatum' }
    ];

    colDef = [
        { sortField: 'rgs', text: 'Regionalschlüssel' },
        { sortField: 'name', text: 'Name der Kommune' },
        { sortField: 'district', text: 'Regierungsbezirk' },
        { sortField: 'county', text: 'Kreis'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr>
                <td>{row.rgs}</td>
                <td>{row.name}</td>
                <td>{row.district}</td>
                <td>{row.county}</td>
                {this.renderTableCellActions(row)}
            </tr>
        )
    }


    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/regions/${row.id}/edit`, 'edit', 'bearbeiten')}
                {this.renderTableCellActionIcon(`/procedures/${row.id}/new`, 'plus', 'Verfahren hinzufügen')}
            </td>
        )
    }
}