import React from "react";
import PropTypes from 'prop-types';
const { Component } = React;

import ReactModal from 'react-modal';
import RegionDetails from "./RegionDetails";

export default class RegionDetailModal extends Component {

    static propTypes ={
        regionId: PropTypes.number,
        onClose: PropTypes.func
    }

    modalStyles = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1002,
            maxHeight: '90vh',
            overflowY: 'scroll'
        },
        overlay: {
            zIndex: 1001
        }
    };

    render() {
        return <ReactModal
            isOpen={!!this.props.regionId}
            contentLabel="Minimal Modal Example"
            style={this.modalStyles}
            ariaHideApp={false}
        >

            <RegionDetails regionId={this.props.regionId}>
                <div className='col'>
                    <div style={{float: 'right'}}>
                        <a className='btn btn-icon btn-primary' href={`/regions/${this.props.regionId}/print`} target={'_blank'}><i className="fa fa-print" aria-hidden="true"></i></a>
                        <button className='btn btn-icon btn-primary' onClick={() => this.props.onClose()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                </div>
            </RegionDetails>
        </ReactModal>
    }

}