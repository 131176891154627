import React from "react";
let sortBy = require('lodash.sortby');
const { Component } = React;
import PropTypes from 'prop-types';
import RegionDetailModal from "./RegionDetailModal";

export default class SearchTableRegion extends Component {

    static propTypes = {
        regions: PropTypes.array
    }

    state = {
        detailModalForId: null,
        sortField: 'name',
        sortDesc: false
    }

    sortedRegions() {
        if (!this.props.regions) {
            return []
        }
        let result = sortBy(this.props.regions, [(region) => region[this.state.sortField]])
        if (this.state.sortDesc) {
            result = result.reverse()
        }
        return result
    }

    setSort(colName) {
        if (colName === this.state.sortField) {
            this.setState({sortDesc: !this.state.sortDesc})
        } else {
            this.setState({sortField: colName, sortDesc: false})
        }
    }

    sortIndicator(colName) {
        if(colName !== this.state.sortField) {
            return <i className={`fa fa-sort`} aria-hidden="true"></i>
        }
        return <i className={`fa fa-sort-${this.state.sortDesc ? 'desc' : 'asc'}`} aria-hidden="true"></i>
    }

    getHeader(label, colName) {
        return <th style={{'cursor': 'pointer'}} onClick={() => this.setSort(colName)}>
            {label} {this.sortIndicator(colName)}
        </th>
    }



    componentDidMount() {

    }


    render() {
        return (
            <div style={{height: '100%'}}>
                <div className="container-fluid mt-4">
                    <div className="row-fluid">
                        <div className="col-md-12">
                            <h1>Daten</h1>
                            <p>Die Liste zeigt alle {this.props.regions.length} Kommunen an, die über die Filter oben ausgewählt wurden.
                                Die Auswahl passt sich dynamisch an und kann zusätzlich per Klick auf eine der drei Kategorien auf- oder absteigend sortiert werden.
                            </p>
                            <table className='table'>
                                <thead>
                                <tr>
                                    {this.getHeader('Kommune', 'name')}
                                    {this.getHeader('Einwohner', 'population')}
                                    {this.getHeader('Online-Partizipationsverfahren', 'allCount')}
                                </tr>
                                </thead>
                                <tbody>
                                {this.sortedRegions() && this.sortedRegions().map(region => <tr key={region.id}>
                                    <td>
                                        <a href='#' onClick={() => this.setState({detailModalForId: region.id})}>{region.name}</a>
                                    </td>
                                    <td>
                                        {region.population}
                                    </td>
                                    <td>
                                        {region.allCount}
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                            <RegionDetailModal regionId={this.state.detailModalForId} onClose={() => this.setState({detailModalForId: null})}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
