import React from "react";
import RegionList from "./RegionList";
import ProcedureFilter from "./ProcedureFilter";

const { Component } = React;

export default class RegionListContainer extends Component {

    state = {
        filteredRegions: []
    }

    onChangeFilter(filterValues) {
        $.ajax({
            type: "POST",
            data : JSON.stringify(filterValues),
            url: "/api/regions-list/filtered", //Todo: Include details
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: (result) => {
                this.setState({filteredRegions: result})
            }
        })
    }

    render() {
        return <div style={{ display: 'flex', flexDirection: 'column'}}>
            <ProcedureFilter onChange={(filterValues) => this.onChangeFilter(filterValues)}/>
            <RegionList style={{flex: 1}} regions={this.state.filteredRegions}/>
        </div>
    }
}
