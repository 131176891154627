import React from "react";
import Slider from 'rc-slider'
const Range = Slider.Range
const TooltipRange = Slider.createSliderWithTooltip(Slider.Range)
import { Picky } from 'react-picky';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip'


const { Component } = React;
export const FILTER_STATE = 'FILTER_STATE'

export default class ProcedureFilter extends Component {

    static propTypes = {
        onChange: PropTypes.func
    };

    state = {
        meta: null,
        filter: {
            procedures: [0, 0],
            timerange: [0, 0],
            population: [0, 0],
            procedureTypes: []
        }
    }

    populationMap = {
        0: 0,
        1: 20000,
        2: 100000,
        3: 1000000,
        4: 9999999
    }

    sliderStyles = { width: '300px', marginBottom: '20px' }
    selectStyles = {
        width: '300px'
    }

    componentDidMount() {


        $.getJSON('/api/filter-metadata', result => {
            this.setState({meta: result})

            const stateJsonString = localStorage.getItem(FILTER_STATE)
            if (stateJsonString) {
                this.initializeStateFromStorage(stateJsonString);
            } else {
                this.initializeState(result);
            }
        })
    }

    initializeState(result) {
        const filter = this.createInitialFilterState(result)
        this.setState({filter})
        this.props.onChange(this.getOutput(filter))
        localStorage.setItem(FILTER_STATE, JSON.stringify({filter, meta: result}))
    }

    initializeStateFromStorage(stateJsonString) {
        const persistedState = JSON.parse(stateJsonString)
        this.setState({filter: persistedState.filter})
        this.props.onChange(this.getOutput(persistedState.filter))
    }


    getOutput(filterValues) {
        const result = Object.assign({}, filterValues)
        result.population = [this.populationMap[filterValues.population[0]], this.populationMap[filterValues.population[1]]]
        return result
    }

    createInitialFilterState(meta) {
        return {
            procedures: [1, meta.maxProcedures],
            timerange: [meta.minTimerange, meta.maxTimerange],
            population: [0, 4],
            procedureTypes: []
        }
    }

    setVal(field, newVal) {
        const filters = Object.assign({}, this.state.filter);
        filters[field] = newVal;
        this.props.onChange(this.getOutput(filters))
        localStorage.setItem(FILTER_STATE, JSON.stringify({filter: filters, meta: this.state.meta}))
        this.setState({filter: filters});
    }

    componentDidUpdate() {
    }

    getSlideProcedureCountMarks(max) {
        const result = {};
        result[0] = 0;
        result[max] = max;
        for (let i = 1; i < max; i++) {
            if (i % 5 === 0) {
                result[i] = i
            }
        }
        return result
    }

    getScaleStartForYear(min){
        let i = min;
        while (true) {
            if (i % 5 === 0) {
                return i
            }
            i--
        }
    }

    getScaleEndForYear(max){
        let i = max;
        while (true) {
            if (i % 5 === 0) {
                return i
            }
            i++
        }
    }

    getYearMarks(min, max) {
        const result = {};
        const scaleStart = this.getScaleStartForYear(min)
        result[scaleStart] = scaleStart;
        const scaleEnd = this.getScaleEndForYear(max)
        result[scaleEnd] = scaleEnd;
        for (let i = min; i < max; i++) {
            if (i % 5 === 0) {
                result[i] = i
            }
        }
        return result
    }

    render() {
        if (!this.state.meta){
            return <div></div>
        }
        return <div>
            <div className='row justify-content-start' style={{marginLeft: 0}}>
                <div className='col-md-auto mt-2'>
                    <h5>Auswahl filtern <i className="fa fa-info-circle" data-tip data-for='i-tooltip' aria-hidden="true"></i></h5>
                    <ReactTooltip style={{}}id='i-tooltip' place='bottom' effect='solid' multiline={true}>
                        <div style={{width: '200px'}}>
                            Die Karte zeigt alle Kommunen
                        in Grün an, die die gewählten Filterbedingungen
                        erfüllen. Insgesamt sind die fünf Filter als
                        UND-Zusammenhang miteinander verknüpft.
                        Die Auswahlmöglichkeiten innerhalb eines Filters
                        sind als ODER-Bedingung zu verstehen.
                        </div>
                </ReactTooltip>
                </div>
            </div>
            <div className='row justify-content-start silder-container' style={{marginLeft: 0}}>

                <div className="col-md-auto ml-1 pr-4">
                    <label>Durchgeführte Verfahren</label>
                    <TooltipRange
                        style={this.sliderStyles}
                        min={0}
                        max={this.state.meta.maxProcedures}
                        value={this.state.filter.procedures}
                        onChange={(newVal) => this.setVal('procedures', newVal)}
                        allowCross={false}
                        defaultValue={[0, this.state.meta.maxProcedures]}
                        marks={ this.getSlideProcedureCountMarks(this.state.meta.maxProcedures) }
                        step={1}
                        tipProps={{placement: 'bottom'}}
                    />
                </div>
                <div className="col-md-auto ml-2 pr-4">
                    <label>Zeitraum</label>
                    <TooltipRange
                        style={this.sliderStyles}
                        min={this.getScaleStartForYear(this.state.meta.minTimerange)}
                        max={this.getScaleEndForYear(this.state.meta.maxTimerange)}
                        onChange={(newVal) => this.setVal('timerange', newVal)}
                        allowCross={false}
                        value={this.state.filter.timerange}
                        defaultValue={[this.state.meta.minTimerange, this.state.meta.maxTimerange]}
                        marks={this.getYearMarks(this.state.meta.minTimerange, this.state.meta.maxTimerange)}
                        step={1}
                        tipProps={{placement: 'bottom'}}
                    />
                </div>
                <div className="col-md-auto ml-2 pr-5">
                    <label>Gemeindegröße</label>
                    <Range
                        style={this.sliderStyles}
                        value={this.state.filter.population}
                        onChange={(newVal) => this.setVal('population', newVal)}
                        allowCross={false}
                        max={4}
                        step={1}
                        defaultValue={[0, 4]}
                        marks={{0: '0', 1: '20.000', 2: '100.000', 3: '1.000.000', 4: '>1.000.000'}}
                        values={[0, 1, 2, 3 ,4]}
                    />
                </div>
                <div className="col-md-auto pb-2">
                    <div style={this.selectStyles}>
                        <label>Verfahrensart</label>
                        <Picky
                            options={this.state.meta.procedureTypes}
                            numberDisplayed={1}
                            multiple={true}
                            includeSelectAll={true}
                            valueKey={'key'}
                            labelKey={'label'}
                            value={this.state.filter.procedureTypes}
                            onChange={(newVal) => this.setVal('procedureTypes', newVal)}
                            dropdownHeight={600}

                            selectAllText={'Alle auswählen'}
                            manySelectedPlaceholder={'%s auswählt'}
                            allSelectedPlaceholder={'%s auswählt'}
                            placeholder={'Nichts auswählt'}
                        />
                    </div>
                </div>

            </div>
        </div>
    }
}
