import '../sass/base.scss'
import '../sass/webapp.scss'

import React from "react";
import ReactDOM from "react-dom";
import 'select2';

import Common from './Common';
import SearchTableRegion from "./SearchTable/SearchTableRegion";
import SearchTableProcedure from "./SearchTable/SearchTableProcedure";
import ProcedureMapContainer from "./ProcedureMapContainer";
import RegionListContainer from "./RegionListContainer";
import RegionDetails from "./RegionDetails";

$(document).ready(function () {
    window.common = new Common();

    if ($('#region-search-results').length) {
        ReactDOM.render(
            <SearchTableRegion ref={(el) => {window.regionSearchResults = el}} />,
            document.getElementById("region-search-results")
        );
    }

    if ($('#procedure-search-results').length) {
        ReactDOM.render(
            <SearchTableProcedure ref={(el) => {window.procedureSearchResults = el}} />,
            document.getElementById("procedure-search-results")
        );
    }

    if ($('#procedure-map-container').length) {
        ReactDOM.render(
            <ProcedureMapContainer ref={(el) => {window.procedureMapContainer = el}} />,
            document.getElementById("procedure-map-container")
        );
    }

    if ($('#region-list-container').length) {
        ReactDOM.render(
            <RegionListContainer ref={(el) => {window.regionListContainer = el}} />,
            document.getElementById("region-list-container")
        );
    }

    if ($('#print-region-details').length) {
        ReactDOM.render(
            <RegionDetails regionId={window.regionId} print={true} ref={(el) => {window.regionDetails = el}} />,
            document.getElementById("print-region-details")
        );
    }

    $('.select2-enable').select2()
});
