import React from "react";

const { Component } = React;
import {Map, Popup, TileLayer, Polygon } from 'react-leaflet'
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import RegionDetailModal from "./RegionDetailModal";

export default class SearchTableRegion extends Component {

    static propTypes = {
        highlightedRegions: PropTypes.array
    }

    state = {
        detailModalForId: null,
        center: [51.4332, 7.6616],
        nrw_polygon: [],
        regions: []
    }




    componentDidMount() {
        $.getJSON('/static/json/nrw.json', (data) => {
            this.setState({nrw_polygon: [[[-90, -180], [-90, 180], [90, 180], [90, -180]], data]})
        })
        $.getJSON('/api/regions-map', (data) => {
            this.setState({regions: data})
        })
    }

    getMapColor(region) {
        if (this.props.highlightedRegions.find(regionId => region.id === regionId)) {
            return '#00AA00' //Is highlighted
        }
        return '#8888CC' // Is not highlighted
    }


    render() {
        return (
            <div style={{height: '100%'}}>
                <Map center={this.state.center} zoom={8} style={{height: '100%'}}>
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmV0ZWlsaWd1bmdoaHUiLCJhIjoiY2lzbTRmcTh2MDA4YTJzcGtxMW1qYjQ0eCJ9.8tOzZNVzlelqwlCh8-sj2A"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    <Polygon positions={this.state.nrw_polygon}
                             fillColor={'#000000'}
                             fillOpacity={0.1}
                             color={'#000000'}
                             weight={1}
                    />
                    { this.state.regions.map(region =>
                        <Polygon positions={region.area}
                                 key={region.id}
                                 fillColor={this.getMapColor(region)}
                                 fillOpacity={0.2}
                                 color={this.getMapColor(region)}
                                 weight={1}
                                 onMouseOver={(e) => {
                                     e.target.openPopup();
                                 }}
                                 onMouseOut={(e) => {
                                     e.target.closePopup();
                                 }}
                                 onClick={() => {
                                     this.setState({detailModalForId: region.id})
                                 }}
                        >    <Popup closeButton={false}>{region.name}</Popup>
                        </Polygon>)}
                </Map>
                <RegionDetailModal regionId={this.state.detailModalForId} onClose={() => this.setState({detailModalForId: null})}/>
            </div>
        )
    }
}
