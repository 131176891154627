import React from "react";
import PropTypes from 'prop-types';
import {FILTER_STATE} from "./ProcedureFilter";
const { Component } = React;


export default class RegionDetails extends Component {
    static propTypes = {
        regionId: PropTypes.number,
        print: PropTypes.bool
    };

    state = {
        filterCache: null,
        details: null,
    }

    componentDidMount() {
        $.getJSON(`/api/regions/${this.props.regionId}`, (result) => {
            this.setState({details: result})
            if (this.props.print) {
                setTimeout(() => {print(); window.close()}, 1000)
            }
        })
        this.setState({filterCache: JSON.parse(localStorage.getItem(FILTER_STATE)).filter})
    }

    hasAddress() {
        return this.state.details.contact_address ||
            this.state.details.contact_department ||
            this.state.details.contact_name ||
            this.state.details.contact_task
    }

    hasContact() {
        return this.state.details.contact_telephone ||
            this.state.details.contact_mail ||
            this.state.details.contact_url
    }

    linkifyPhonenumber(phoneNumber) {
        return 'tel:+49' + phoneNumber.replace(/\D/g,'').substring(1)
    }

    isSelectedProcedureType(typeId) {
        if (this.state.filterCache && typeId) {
            if (!this.state.filterCache.procedureTypes.length) {
                return true
            }
            const typeIdToSearch = typeId >= 6 ? 6 : typeId; //special case for misc types
            return this.state.filterCache.procedureTypes.find(type => type.key === typeIdToSearch)
        }
    }

    isSelectedTimeRange(start, end) {
        if (this.state.filterCache && start && end) {
            return start >= this.state.filterCache.timerange[0] &&  end <=this.state.filterCache.timerange[1]
        }
    }

    isSelectedProcedure(procedure) {
        return this.isSelectedProcedureType(procedure.procedure_type) && this.isSelectedTimeRange(procedure.start, procedure.end)
    }

    has_custom_field_values(obj) {
        // obj being either a procedure or region
        return !!obj.custom_fields_with_values.length && obj.custom_fields_with_values.some(cf => cf.value)
    }

    custom_field_data(obj) {
        // obj being either a procedure or region
        return this.has_custom_field_values(obj) && <div className='row'>
            <div className='col-12 customfield-data'>
                <h3>Zusatzinformationen</h3>
                <ul>
                    {this.state.details.custom_fields_with_values.filter(cf => cf.value).map(cf => <li key={cf.key}>
                        {cf.type == 'bool' ? <span>
                                <i className="fa fa-check" aria-hidden="true"></i> {cf.name}
                                </span> :
                            <span>{cf.name}: {cf.value}</span>}
                    </li>)}
                </ul>
            </div>
        </div>
    }

    custom_field_data_as_sentence(obj) {
        if (!this.has_custom_field_values(obj)) {
            return <span></span>
        }
        const strings = obj.custom_fields_with_values.filter(cf => cf.value).map(cf => {
            let string = `${cf.name}: `
            string += cf.value === true ? 'Ja' : cf.value
            return string
        })
        return  <span> Es sind folgende zusatzinformationen vorhanden: {strings.join(', ')}</span>
    }

    /*
     combines the modified dates of a region and procedures as well as custom fields to get the max date
     */
    getMaxModifiedDate() {
        if (!this.state.details) {
            return null
        }
        let dates = this.state.details.procedures.map(procedure => procedure.modified)
        dates.push(this.state.details.modified)
        dates = dates.concat(this.state.details.custom_fields_with_values.filter(cf => cf.modified).map(cf => cf.modified))
        dates = dates.concat(this.state.details.procedures.reduce((cfDates, procedure) => {
            return cfDates.concat(procedure.custom_fields_with_values.filter(cf => cf.modified).map(cf => cf.modified))
        }, []))
        return dates.map(date => new Date(date)).reduce(function (a, b) { return a > b ? a : b; });
    }

    render() {
        if (!this.state.details) {
            return <div></div>
        }
        return <div className='container'>
            <div className='row'>
                <div className='col'>
                    <h1>{this.state.details.name}</h1>
                    <small>Letzte Änderung: {common.datetimeify(this.getMaxModifiedDate())}</small>
                </div>
                {this.props.children}
            </div>
            <div className='row'>
                <div className='col-12'>
                    {
                        this.state.details.district && <span>Regierungsbezirk {this.state.details.district}, </span>
                    }
                    {
                        this.state.details.county && <span>Landkreis {this.state.details.county}, </span>
                    }
                    <span>{this.state.details.population || 'unbekannte Anzahl'} Einwohner</span>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <h3>Online-Partizipationsverfahren</h3>
                    <p>Insgesamt wurden bisher {this.state.details.all_count || 0} Verfahren erfasst{this.state.details.all_count ? ':' : '.'}</p>
                    <ul className={'procedure-statistics'}>
                        { !!this.state.details.blp_count &&
                        <li>{this.state.details.blp_count} Bauleitplanverfahren</li>}
                        { !!this.state.details.hhp_count &&
                        <li>{this.state.details.hhp_count} Bürgerhaushalt{this.state.details.hhp_count > 1 && 'e'}</li>}
                        { !!this.state.details.sep_count &&
                        <li>{this.state.details.sep_count} Stadtentwicklungsplanverfahren</li>}
                        { !!this.state.details.lap_count &&
                        <li>{this.state.details.lap_count} Lärmaktionsplanverfahren</li>}
                        { !!this.state.details.mmm_count &&
                        <li>{this.state.details.mmm_count} Mangelmanagementverfahren</li>}
                        { !!this.state.details.others_count &&
                        <li>{this.state.details.others_count} sonstige Verfahren</li>}
                        { this.state.details.has_budget && <li>Budget für Bürgerbeteiligung vorhanden</li>}
                        { this.state.details.has_guidelines && <li>Leitlinien für Bürgerbeteiligung vorhanden</li>}
                        { this.state.details.has_guidelines_planned && <li>Leitlinien für Bürgerbeteiligung geplant</li>}
                        { this.state.details.has_participation_manager && <li>Beteiligungsbeauftragter vorhanden</li>}
                    </ul>
                    { this.custom_field_data(this.state.details) }
                </div>
                <div className='col-6'>
                    <h3>Kontakt</h3>
                    {!this.hasAddress() && !this.hasContact() ? <p style={{fontStyle: 'italic'}}>
                            Keine Kontaktdaten hinterlegt.
                        </p> :
                        <ul className={'region-contactdata'}>
                            {this.state.details.contact_name && <li>
                                {this.state.details.contact_name}
                            </li>}
                            {this.state.details.contact_department && <li>
                                {this.state.details.contact_department}
                            </li>}
                            {this.state.details.contact_address && <li>
                                {this.state.details.contact_address}
                            </li>}
                            {this.state.details.contact_task && <li>
                                {this.state.details.contact_task}
                            </li>}
                            {this.state.details.contact_telephone && <li>
                                Tel: <a href={this.linkifyPhonenumber(this.state.details.contact_telephone)}>{this.state.details.contact_telephone}</a>
                            </li>}
                            {this.state.details.contact_mail && <li>
                                E-mail: <a href={'mailto:' + this.state.details.contact_mail}>{this.state.details.contact_mail}</a>
                            </li>}
                            {this.state.details.contact_url && <li>
                                Web: <a href={this.state.details.contact_url}>{this.state.details.contact_mail}</a>
                            </li>}
                        </ul>
                    }
                </div>
            </div>
            {!!this.state.details.procedures.length && <div className='row'>
                <div className='col-12'>
                    <h3>Verfahren im Detail</h3>
                    <p>Nicht zu allen Verfahren wurden detaillierte Angaben gemacht. Zu den folgenden Verfahren liegen Informationen vor:</p>

                    {this.state.details.procedures.map(procedure => <div key={procedure.id} className={!this.isSelectedProcedure(procedure) ? 'text-muted' : ''}>
                        <h4>{procedure.name}</h4>
                        <p>
                            { (procedure.start || procedure.end || procedure.is_ongoing) && <span>Das Verfahren
                            {procedure.start && ` startete ${procedure.start}`}
                            {procedure.start && procedure.end && ` und `}
                            {procedure.end ? `endete ${procedure.end}` : procedure.is_ongoing ? ' dauert noch an' : ''}
                                .</span>}
                            {procedure.resort && ` Das Verfahren wurde vom Verwaltungsressort ${procedure.resort} durchgeführt.`}
                            {procedure.procedure_type_str && ` Es handelt sich um ein Verfahren aus der Kategorie ${procedure.procedure_type_str}.`}
                            {this.custom_field_data_as_sentence(procedure)}
                        </p>
                    </div>)}
                </div>
            </div> }
        </div>
    }
}
