import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableRegion extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'desc',
        page: 1
    };
    formId = 'procedure-search-form';
    varPrefix = 'searchTableProcedure';
    loadParamsRegExp = [
        /\/region\/(.*)/g
    ];

    apiUrl = '/api/procedures';
    sortDef = [
        { key: 'name', name: 'Name des Verfahrens' },
        { key: 'region', name: 'Name der Kommune' },
        { key: 'start', name: 'Start' },
        { key: 'end', name: 'Ende'},
        { key: 'rgs', name: 'Regionalschlüssel'},
        { key: 'updated', name: 'Änderungsdatum' }
    ];

    colDef = [
        { sortField: 'rgs', text: 'Regionalschlüssel' },
        { sortField: 'region', text: 'Name der Kommune' },
        { sortField: 'name', text: 'Name des Verfahrens' },
        { sortField: 'start', text: 'Start' },
        { sortField: 'end', text: 'Ende'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr>
                <td>{row.rgs}</td>
                <td>{row.region}</td>
                <td>{row.name}</td>
                <td>{row.start}</td>
                <td>{row.end}</td>
                {this.renderTableCellActions(row)}
            </tr>
        )
    }


    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/procedures/${row.id}/edit`, 'edit', 'bearbeiten')}
                {this.renderTableCellActionIcon(`/procedures/${row.id}/delete`, 'trash', 'löschen', 'danger')}
            </td>
        )
    }
}